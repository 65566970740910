import React from 'react'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'
import Button from '../components/button'
import { Link } from 'gatsby'

export default () => {
  const title = 'Careers'
  return (
    <Layout>
      <Helmet title={title} />
      <div className="wrap px-6 mx-auto py-8 md:px-20 md:pt-12 md:pb-32">
        <div className="pb-6 md:pb-0 md:flex md:flex-wrap md:pb-0">
          <div>
            <h1 className="uppercase font-light tracking-widest text-gray-33 text-20 md:text-36">
              CAREERS
            </h1>
          </div>
          <div className="mt-6 md:mt-40 md:mb-20 md:flex-1 md:max-w-640">
            <h3 className="text-17 text-gray-33 tracking-wide font-medium md:text-2xl">
              公司人員招募計畫
            </h3>
            <p className="text-13-23 text-gray-4d font-regular mt-8 md:font-light md:text-20-40">
              工作理念
              <br />
              我們瞭解UI、UX應各有專職(我們亦有專職的UX
              planner)但我們希望共同作業的UI設計師應具UX & Design Thinking
              mindset並共同創造一個完整的工作流程與環境
              <br />
              <br />
              職缺 : UX/UI 資深設計師
            </p>
            <Button className="mt-12 px-8 tracking-wider">我要應徵</Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}
